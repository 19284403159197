import type { NitroFetchOptions, NitroFetchRequest } from 'nitropack'

export function fetchAPIAPVC<
  T = unknown,
  R extends NitroFetchRequest = NitroFetchRequest,
  O extends NitroFetchOptions<R> = NitroFetchOptions<R>,
>(request: R, options?: O) {
  const { $apiAPVC } = useNuxtApp()
  return $apiAPVC<T, R, O>(request, options)
}
